import React from 'react'
import { Link } from 'react-router-dom'

export default function Navigation() {
  return (
    <div className='page-links'>
      <NavItem url='/about' text='about' className='column-right' />
      <NavItem url='/books' text='books' className='column-right' />
      <NavItem url='/contact' text='contact' />
    </div>
  )
}

function NavItem({ url, text, className }: { url: string, text: string, className?: string }) {
  return (
    <Link to={url} className={'link no-underline' + (className ? ' ' + className : '')}>
      {text}
    </Link>
  )
}
