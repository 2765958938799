import React from 'react'
import { Link } from 'react-router-dom'

export default function Name() {
  return (
    <h1 className='name'>
      <Link to='/'>J.&nbsp;R&nbsp;.&nbsp;Wilding</Link>
    </h1>
  )
}
