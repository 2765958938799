import React from 'react'
import Page from '../partials/Page'

const books = [
  {
    image: '/assets/images/draugma-and-dreamers.jpeg',
    title: 'Draugma and Dreamers',
    genre: 'Middle Grade Fantasy',
    description: '13-year-old Wren wants two things: magic of her own, and not to share a body with her sister. When she gets the first, she sets her sights on the second. But when she accidentally traps her sister inside a monster that eats dream magic, she must find a way to save her sister and kill the monster, before it takes Wren\'s magic and leaves her with nothing.'
  }, {
    image: '/assets/images/deep-sea-fishing.jpeg',
    title: 'Deep Sea Fishing (Working Title)',
    genre: 'Young Adult Contemporary Romance',
    description: '17-year-old Rayne Gibson, or Ray, can\'t stand the rich tourists who frequent the deep sea fishing boat she works on in a small coastal Florida town, and cute boy Scott is as obnoxious as the rest of them. However, Ray soon learns there\'s more to Scott than meets the eye. As their relationship develops, she must navigate her sister\'s wedding, which brings her absentee father back into her life, as well as decide what her own future will hold. Will she stay comfortable and safe in her small town or leave home for college and the unknown?'
  }, {
    image: '/assets/images/escaping-elysia.jpeg',
    title: 'Escaping Elysia',
    genre: 'Young Adult Dystopian',
    description: 'All Evita Creedy, 17, wants is to finish her degree and be accepted for a prestigious job with the city\'s Department of Energy. But when she stops a school shooting using a gun that mysteriously appeared in her purse, she finds herself kidnapped and placed in a secret, underground facility where researchers are attempting to create a genetic modification that enables humans to photosynthesize. Now Evita must find a way to escape with her new friends before the experiments kill her.'
  }
]
export default function Books() {
  return (
    <Page title='Books'>
      {books.map(book => <Book key={book.title.replace(' ', '-').toLocaleLowerCase()} {...book} />)}
    </Page>
  )
}

interface BookType {
  image: string,
  title: string,
  genre: string,
  description: string
}
function Book({ image, title, genre, description }: BookType) {
  return (
    <div className='max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl mb-4'>
      <div className='md:flex'>
        <div className='md:shrink-0'>
          <img src={image} alt={title + '\'s Cover'} className='h-48 w-full object-cover md:h-full md:w-48' />
        </div>
        <div className='py-3 px-4'>
          <div className='text-2xl leading-tight font-semibold text-black'>{title}</div>
          <div className='uppercase tracking-wide text-sm text-indigo-500 font-medium'>{genre}</div>
          <p className='mt-2 text-slate-500'>{description}</p>
        </div>
      </div>
    </div>
  )
  /*return (
    <div className='grid grid-cols-2'>
      <div className='w-1/4'>
        <img src={image} alt={title + '\'s Cover'} />
      </div>
      <div className='w-3/4'>
        <div>{title}</div>
        <div>{genre}</div>
        <div>{description}</div>
      </div>
    </div>
  )*/
}