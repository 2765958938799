import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export default function SocialLinks() {
  return (
    <div className='social-links'>
      <SocialLink url='https://twitter.com/jrwildingwrites' icon={['fab', 'twitter']} />
      <SocialLink url='https://www.instagram.com/jrwildingwrites' icon={['fab', 'instagram']} />
      <SocialLink url='https://www.facebook.com/profile.php?id=100075601692473' icon={['fab', 'facebook-f']} />
    </div>
  )
}

function SocialLink({ url, icon }: { url: string, icon: IconProp }) {
  return (
    <a href={url} className='mx-4' target='_blank' rel='noopener noreferrer'>
      <FontAwesomeIcon icon={icon} />
    </a>
  )
}
