import React from 'react'
import Header from '../partials/Header'
import SocialLinks from '../partials/SocialLinks'
import Name from '../partials/Name'
import Subtext from '../partials/Subtext'
import Navigation from '../partials/Navigation'


export default function Home() {
  return (
    <Header className='min-h-screen'>
      <SocialLinks />
      <Name />
      <Subtext />
      <Navigation />
      {/*<div>
        <p>image</p>
      </div>*/}
      <div style={{ height: '250px' }}></div>
    </Header>
  )
}
