import React from 'react'
import { BrowserRouter, useRoutes } from 'react-router-dom'

import App from './App'
import Home from './pages/Home'
import About from './pages/About'
import Books from './pages/Books'
import Contact from './pages/Contact'

// Using anonymous function because the react-router-dom library took the name Routes
// eslint-disable-next-line import/no-anonymous-default-export
export default function() {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  )
}

function Routes() {
  return useRoutes([{
    element: <App />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/about', element: <About /> },
      { path: '/books', element: <Books /> },
      { path: '/contact', element: <Contact /> }
      // @todo: add { path: '*', element: <NotFound /> }
    ]
  }])
}