import React from 'react'
import Page from '../partials/Page'

export default function About() {
  return (
    <Page title='About'>
      <div className='max-w-md mx-auto bg-white shadow-md overflow-hidden md:max-w-2xl mb-4'>
        <div className='md:flex'>
          <div className='md:shrink-0'>
          <img src='/assets/images/headshot.jpeg' alt='J.R. Wilding Headshot' className='h-48 w-full object-cover md:h-full md:w-48' />
          </div>
          <div className='py-3 px-4'>
            <p className='mt-2'>J. R. Wilding is a member of SCBWI and has been writing novels for over ten years. She graduated with a degree in Learning and Development from the University of Texas at Austin and taught fourth and fifth grade for seven years. Recently, she left teaching to pursue writing full-time. She is currently seeking representation for Draugma and Dreamers, a middle grade fantasy about a girl who learns to wield dream magic to defeat a monster that brings one's worst nightmares to life.</p>
          </div>
        </div>
      </div>
    </Page>
  )
}
