import React, { ReactNode } from 'react'
import Header from '../partials/Header'
import SocialLinks from '../partials/SocialLinks'
import Name from '../partials/Name'
import Subtext from '../partials/Subtext'
import Navigation from '../partials/Navigation'
import Title from '../partials/Title'

export default function Page({ title, children }: { title: string, children?: ReactNode }) {
  return (
    <>
      <Header>
        <SocialLinks />
        <Name />
        <Subtext />
        <Navigation />
      </Header>
      <div className='container mx-auto my-4 p-4 bg-white/75'>
        <Title>{title}</Title>
        <div className='mt-4'>
          {children}
        </div>
      </div>
    </>
  )
}
