import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import Router from './components/Router'
import './assets/main.css'
import "@fontsource/cinzel/latin.css"
import "@fontsource/cinzel-decorative"

const root = createRoot(document.getElementById('root') as HTMLDivElement)
root.render(
  <StrictMode>
    <Router />
  </StrictMode>
)
reportWebVitals()
