import React from 'react'
import Page from '../partials/Page'

export default function Contact() {
  return (
    <Page title='Contact'>
      <div style={{ padding: '0px 25px 25px 25px' }}>
        <div>Email: jessicarwilding@gmail.com</div>
        <div>Twitter: @jrwildingwrites</div>
        <div>Instagram: @jrwildingwrites</div>
      </div>
    </Page>
  )
}
