import React, { ReactNode } from 'react'

interface HeaderProps {
  children?: ReactNode
  className?: string
}
export default function Header({ children, className }: HeaderProps) {
  // @todo: Transform header to menu on scroll
  // sticky top-0
  return (
    <header className={'header flex flex-col items-center justify-center content-center text-center' + (className ? ' ' + className : '')}>
      {children}
    </header>
  )
}
