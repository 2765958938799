import React from 'react'
import { Outlet } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import './App.css'

library.add(fab)

export default function App() {
  return (
    <div id='app' className='min-h-screen w-full'>
      <Outlet />
    </div>
  )
}
